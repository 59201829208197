<div class="flex flex-col min-h-screen" style="background-image: linear-gradient(to right, #BBE9FF, #FFC7ED);">
  <app-header></app-header>
  <div class="flex-1 flex items-center justify-center py-4 px-4 sm:px-6 lg:px-8">
    <div class="max-w-4xl w-full flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden">
      <div class="hidden md:block w-full md:w-1/2 p-8 bg-gradient-to-r from-purple-100 to-purple-200 relative">
        <img src="assets/images/register-org/login-auth.svg" alt="Login Image" class="w-full h-full object-contain">
        <div class="absolute top-0 left-0 p-4">
          <img class="w-16" src="assets/img/logo/logo-vol.png" alt="logo" (click)="goTo('/')"/>
        </div>
      </div>
      <div class="w-full md:w-1/2 flex flex-col">
        <div
          class="p-8 pb-0 flex flex-col items-center justify-center bg-white rounded-t-lg md:rounded-t-none md:rounded-l-lg">
          <div
            class="rounded-full mb-2 h-16 w-16 flex items-center justify-center text-primary-600 bg-primary-600/10" *ngIf="pageExtras['buttonType'] === 'login'">
            <img [src]="pageExtras['profileImage']" class="avatar-image w-full h-full rounded-full shadow-1px-light" alt="Avatar"/>
          </div>
          <h2 class="text-3xl font-semibold mb-2" *ngIf="formType === 'login'" [style.font-family]="'var(--vex-font)'">Welcome Back!</h2>
          <h2 class="text-3xl font-semibold mb-2" *ngIf="formType === 'signIn'" [style.font-family]="'var(--vex-font)'">Join Us!</h2>
          <p class="text-lg text-gray-600" *ngIf="formType === 'login'" [style.font-family]="'var(--vex-font)'">Sign in with your credentials below.</p>
          <p class="text-lg text-gray-600" *ngIf="formType === 'signIn'" [style.font-family]="'var(--vex-font)'">Create your account now.</p>
          <img *ngIf="pageExtras['imageUrl']" [src]="pageExtras['imageUrl']" alt="logo" class="logo-icon">
        </div>
        <form [formGroup]="loginForm" class="p-8 flex flex-col gap-6 bg-white" *ngIf="formType === 'login'">
          <div class="flex flex-col gap-4">
            <!--<mat-form-field appearance="outline">
              <mat-select class="mx-auto"
                          [style.font-family]="'var(&#45;&#45;vex-font)'"
                          placeholder="Select Session"
                          formControlName="session_id">
                <mat-option *ngFor="let session of sessions" [value]="session?.id"
                            [style.font-family]="'var(&#45;&#45;vex-font)'">
                  {{ session?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>-->
            <mat-form-field appearance="outline" [hideRequiredMarker]="true">
              <mat-label>Username</mat-label>
              <input matInput placeholder="Username" formControlName="username" required   [style.font-family]="'var(--vex-font)'">
              <small class="text-red-500" *ngIf="submitted && loginForm.get('username').hasError('required')">
                Username is required
              </small>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('username')?.errors?.minlength">
                Username must be at least {{loginForm.get('username')?.errors?.minlength?.requiredLength}} characters long
              </small>
            </mat-form-field>
            <mat-form-field appearance="outline" [hideRequiredMarker]="true">
              <mat-label>Password</mat-label>
              <input [type]="inputType" formControlName="password" matInput required [style.font-family]="'var(--vex-font)'">
              <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility"
                      type="button" [style.font-family]="'var(--vex-font)'">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility" [style.font-family]="'var(--vex-font)'"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off" [style.font-family]="'var(--vex-font)'"></mat-icon>
              </button>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('password').hasError('required')">
                Password is required.
              </small>
              <small class="text-red-500" *ngIf="submitted && loginForm.get('password')?.errors?.minlength">
                 Password must be at least {{loginForm.get('password')?.errors?.minlength?.requiredLength}} characters long.
              </small>
            </mat-form-field>
          </div>
          <div class="flex items-center justify-between">
            <mat-checkbox color="primary" [style.font-family]="'var(--vex-font)'">Remember Me</mat-checkbox>
            <a class="text-blue-500" href="#" [style.font-family]="'var(--vex-font)'">Forgot Password?</a>
          </div>
          <button (click)="login()" color="primary" mat-raised-button class="w-full py-2 mt-4 bg-blue-500 text-white rounded-lg" [style.font-family]="'var(--vex-font)'">
            Log In
          </button>
          <!--<button *ngIf="pageExtras['buttonType'] === 'search'" (click)="getUsers()" color="primary" mat-raised-button
                  class="w-full py-2 mt-4 bg-blue-500 text-white rounded-lg" [style.font-family]="'var(&#45;&#45;vex-font)'">Search Organisation
          </button>-->
          <p class="text-gray-600 text-center mt-4" [style.font-family]="'var(--vex-font)'">Don't have an account?<br/>
            <a class="text-blue-500 cursor-pointer" (click)="goTo('/auth/register')">
              Create one
            </a>
          </p>
        </form>
        <form [formGroup]="signInForm" class="p-8 flex flex-col gap-6 bg-white" *ngIf="formType === 'signIn'">
          <div class="flex flex-col gap-4">
            <mat-form-field appearance="outline">
              <mat-label>Organisation Name</mat-label>
              <input matInput placeholder="Organisation name" formControlName="organisation_name" required>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('organisation_name').hasError('required')">Organisation
                name is required</small>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Name" formControlName="name" required>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('name').hasError('required')">Name is
                required</small>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email" required>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('email').hasError('required')">Email is
                required</small>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Contact</mat-label>
              <input matInput placeholder="Contact" formControlName="contact" required>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('contact').hasError('required')">Contact is
                required</small>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput placeholder="Username" formControlName="username" required>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('username').hasError('required')">Username
                is required</small>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input [type]="inputType" formControlName="password" matInput required>
              <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility"
                      type="button">
                <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
                <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
              </button>
              <small class="text-red-500" *ngIf="submitted && signInForm.get('password').hasError('required')">
                Password is required
              </small>
            </mat-form-field>
          </div>
          <!--<button (click)="otp()" color="primary" mat-raised-button class="w-full py-2 mt-4 bg-blue-500 text-white rounded-lg">
            SIGN UP
          </button>-->
          <p class="text-gray-600 text-center mt-4">Already have an account?<br/>
            <a class="text-blue-500 cursor-pointer" (click)="loginFormVisible()">Login here</a>
          </p>
        </form>
        <!--<form [formGroup]="otpForm" class="p-8 flex flex-col gap-6 bg-white" *ngIf="formType === 'otpForm'">
          <div class="flex flex-col">
            <mat-form-field appearance="outline">
              <mat-label>Enter OTP</mat-label>
              <input matInput placeholder="OTP" formControlName="otp" required>
              <small class="text-red-500" *ngIf="submitted && otpForm.get('otp').hasError('required')">OTP is
                required</small>
            </mat-form-field>
          </div>
          <button (click)="signIn()" color="primary" mat-raised-button
                  class="w-full py-2 mt-4 bg-blue-500 text-white rounded-lg" [disabled]="is_submitting">
            <span *ngIf="!is_submitting">SIGN UP</span>
            <span class="loader p-2" *ngIf="is_submitting">Registration in process. Please wait...</span>
          </button>
        </form>-->
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
