import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';
import { AppUrl } from '../constants/app-url';
import { data } from 'autoprefixer';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends BaseService {
  constructor(
    public http: HttpClient,
    public alertService: AlertService,
    public globalService: GlobalService
  ) {
    super(http, alertService, globalService);
  }

  get(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(), data);
  }

  getAttandance(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_ATTANDENCE(), data);
  }

  getIssuedBooks(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_ISSUED_BOOK(), data);
  }

  getLibraryTransaction(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_LIBRARY_TRANSACTION(), data);
  }

  getStudentTransaction(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_TRANSACTION(), data);
  }

  add(data: {}): Observable<any> {
    return this.postRequest(AppUrl.STUDENT(), data);
  }

  detail(data: {}, uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.STUDENT(uniqueId), data);
  }

  count(data: {}): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_COUNT(), data);
  }

  addPersonalInfo(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_PERSONAL_INFO(uniqueId), data);
  }

  addStudentPhotograph(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_PHOTOGRAPH(uniqueId), data);
  }

  addGovtPhotoId(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_GOVT_PHOTO_ID(uniqueId), data);
  }

  addStudentSignature(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_SIGNATURE(uniqueId), data);
  }

  addAddress(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_ADDRESS_INFO(uniqueId), data);
  }

  addAcademic(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_ACADEMIC_DETAIL(uniqueId), data);
  }

  addTenthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_TENTH_CERTIFICATE(uniqueId), data);
  }

  addTwelthCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_TWELTH_CERTIFICATE(uniqueId), data);
  }

  addMoreQualification(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_MORE_QUALIFICATION(uniqueId), data);
  }

  addPreviousCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.STUDENT_PREVIOUS_CERTIFICATE(uniqueId), data);
  }

  addGraduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(
      AppUrl.STUDENT_GRADUATION_CERTIFICATE(uniqueId),
      data
    );
  }

  addPostGraduationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(
      AppUrl.STUDENT_POST_GRADUATION_CERTIFICATE(uniqueId),
      data
    );
  }

  addOtherQualificationCertificate(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(
      AppUrl.STUDENT_OTHER_QUALIFICATION_CERTIFICATE(uniqueId),
      data
    );
  }

  addCourseDetail(data: {}, uniqueId: any): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_COURSE_DETAIL(uniqueId), data);
  }

  getCourseDetail(data: any, uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_COURSE_DETAIL(uniqueId), data);
  }

  getBatchDetail(data: any, uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_BATCH_DETAIL(uniqueId), data);
  }

  getClassDetail(data, uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_CLASS_DETAIL(uniqueId), data);
  }

  getTopics(data: any, uniqueId: any): Observable<any> {
    return this.getRequest(AppUrl.STUDENT_TOPIC_DETAIL(uniqueId), data);
  }

  addAssignment(data: {}): Observable<any> {
    return this.postRequest(AppUrl.STUDENT_SUBMIT_ASSIGNMENT(), data);
  }

  delete(data: {}, uniqueId: any): Observable<any> {
    return this.deleteRequest(AppUrl.STUDENT(uniqueId), data);
  }

  getAssignments(): Observable<any> {
    return this.getRequest(AppUrl.SUBJECT_ASSIGNMENTS());
  }

  /*processStudent(data: {}): Observable<any> {
    return this.putRequest(AppUrl.PROCESS_STUDENT(), data);
  }

  verifyStudent(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.VERIFY_STUDENT(uniqueId), data);
  }

  rejectStudent(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.REJECT_STUDENT(uniqueId), data);
  }

  documentRequiredStudent(data: {}, uniqueId: any): Observable<any> {
    return this.putRequest(AppUrl.DOCUMENT_REQUIRED_STUDENT(uniqueId), data);
  }

  downloadListStudentReport(data: {}): Observable<any> {
    return this.getRequest(AppUrl.LIST_STUDENT_REPORT(), data);
  }

  downloadAllStudentReport(data: {}): Observable<any> {
    return this.getRequest(AppUrl.ALL_STUDENT_REPORT(), data);
  }

  downloadVerifyStudentReport(data: {}): Observable<any> {
    return this.getRequest(AppUrl.VERIFY_STUDENT_REPORT(), data);
  }*/
}
