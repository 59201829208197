import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { AlertService } from '../../../shared/services/alert.service';
import { AuthService } from '../../../shared/services/auth.service';
import { FileService } from '../../../shared/services/file.service';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatFormField } from '@angular/material/form-field';
import { MatLabel } from '@angular/material/select';
import { NgIf } from '@angular/common';
import { MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'vex-profile',
  standalone: true,
  imports: [
    MatLabel,
    NgIf,
    MatCardHeader,
    MatCardTitle,

    ReactiveFormsModule,
    MatFormField,
    MatButton,
    ImageCropperComponent,
    MatDialogContent,
    MatDialogTitle,
    MatInput
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  @ViewChild('imageUpdateDialog') imageUpdateDialog: TemplateRef<any>;
  @ViewChild('showPasswordModal') showPasswordModal: TemplateRef<any>;

  user: any;
  passwordForm: FormGroup;
  profileForm: FormGroup;
  isEditing: any = false;
  submitted: any = false;
  // showPasswordModal: any = false;
  maxSize: number = 2 * 1024 * 1024;
  croppedImage: any = '';
  sendCroppedImage: any;
  private previewImage: string | ArrayBuffer;
  imageChangedEvent: any = '';
  showImageCropper = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
    private fb: FormBuilder,
    private fileUploadService: FileService,
    private cdr: ChangeDetectorRef
    // private imageService: ProfileImageService
  ) {
    this.authService.self().subscribe((data) => {
      if (data && data.id) {
        this.user = data;
        this.route.data.subscribe((routeData) => {
          this.detail();
        });
      }
    });
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    this.passwordForm = this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.profileForm = this.fb.group({
      name: [{ value: '', disabled: true }, [Validators.required]],
      username: [{ value: '', disabled: true }, Validators.required],
      contact: [{ value: '', disabled: true }, [Validators.required]],
      email: [{ value: '', disabled: true }, Validators.required]
    });
  }

  detail(): void {
    this.profileForm.patchValue({
      name: this.user && this.user['name'] ? this.user['name'] : '',
      username: this.user && this.user['username'] ? this.user['username'] : '',
      contact: this.user && this.user['contact'] ? this.user['contact'] : '',
      email: this.user && this.user['email'] ? this.user['email'] : ''
    });
    this.authService.self().subscribe((data) => {});
  }

  updateProfile(): void {
    this.submitted = true;
    if (!this.profileForm.valid) {
      return;
    }
    if (this.submitted) {
      const params: {} = {
        name:
          this.profileForm &&
          this.profileForm.value &&
          this.profileForm.value.name
            ? this.profileForm.value.name
            : '',
        contact:
          this.profileForm &&
          this.profileForm.value &&
          this.profileForm.value.contact
            ? this.profileForm.value.contact
            : '',
        email:
          this.profileForm &&
          this.profileForm.value &&
          this.profileForm.value.email
            ? this.profileForm.value.email
            : ''
      };
      this.authService.update(params).subscribe(
        (data) => {
          this.submitted = false;
          this.profileForm.patchValue({
            name: params['name'],
            contact: params['contact'],
            email: params['email']
          });
        },
        (error) => {
          this.alertService.showErrors(error['error']['errorDetails']);
        }
      );
    }
  }

  openPasswordModal(): void {
    this.passwordForm.patchValue({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    });
    this.dialog.open(this.showPasswordModal, {
      width: '50vw',
      minWidth: '350px'
    });
    // this.showPasswordModal = true;
  }

  updatePassword(): void {
    this.submitted = true;
    if (!this.passwordForm.valid) {
      return;
    }
    if (this.submitted) {
      if (
        this.passwordForm.value.newPassword !==
        this.passwordForm.value.confirmNewPassword
      ) {
        this.alertService.showErrors(
          "Password and confirm password didn't match"
        );
        this.submitted = false;
      } else {
        const params: {} = {
          currentPassword:
            this.passwordForm &&
            this.passwordForm.value &&
            this.passwordForm.value.currentPassword
              ? this.passwordForm.value.currentPassword
              : '',
          newPassword:
            this.passwordForm &&
            this.passwordForm.value &&
            this.passwordForm.value.newPassword
              ? this.passwordForm.value.newPassword
              : ''
        };
        this.authService.updatePassword(params).subscribe(
          (data) => {
            this.submitted = false;
            this.closeModal();
          },
          (error) => {
            this.alertService.showErrors(error['error']['errorDetails']);
            this.submitted = false;
          }
        );
      }
    }
  }

  closeModal(): void {
    this.passwordForm.reset();
    // this.showPasswordModal = false;
  }


  openImageCropper(): void {
    this.dialog.open(this.imageUpdateDialog, {
      width: '40vw',
      minWidth: '350px',
      data: { maxSize: this.maxSize }
    });
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.objectUrl;
    this.convertBlobToBase64(event.blob);
  }

  convertBlobToBase64(blob: Blob): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.sendCroppedImage = reader.result as string;
    };
    reader.readAsDataURL(blob);
  }

  imageLoaded(event: any): void {}

  cropperReady(): void {}

  loadImageFailed(): void {}

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      this.previewFile(file);
    }
  }

  previewFile(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.previewImage = reader.result;
    };
    reader.readAsDataURL(file);
  }

  getImage(event: any): void {
    this.imageChangedEvent = event;
  }

  async fileChangeEvent(event: any, type: any): Promise<void> {
    const params: {} = {
      type: type,
      fileType: 'BASE64'
    };
    this.fileUploadService
      .fileUpload(params, this.sendCroppedImage)
      .subscribe((data) => {
        this.uploadProfileImage(data['data']);
      });
  }

  uploadProfileImage(data: any): void {
    this.user.profile_image = data['url'];
    this.cdr.detectChanges();
    const params: {} = {
      profile_image: data.id
    };
    this.authService.updateProfile(params).subscribe((response) => {
      this.showImageCropper = false;
      this.imageChangedEvent = undefined;
      // this.imageService.shareData(data['url']);
      this.dialog.closeAll();
    });
  }
}
