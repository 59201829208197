<div
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative transition duration-400 ease-out-swift select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">
    {{user?.name}}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary-600 bg-primary-600/10">
    <img [src]="userImageUrl" class="avatar-image w-full h-full rounded-full shadow-1px-light" alt="Avatar" *ngIf="userImageUrl"/>
<!--    <i class="fa-solid fa-circle-user fa-2x" *ngIf="!userImageUrl"></i>-->
  </div>
</div>
