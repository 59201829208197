<div class="h-[100%] py-20 flex items-center ">
  <div class="bg-white my-auto rounded-lg shadow-lg p-6 mb-6 w-11/12 md:w-3/4 lg:w-2/3 mx-auto"
       style="position: relative; top: 20px">
    <div class="flex flex-col items-center mb-6">
      <img [src]="user?.profile_image" alt="profile Image"
           *ngIf="user?.profile_image"
           class="w-32  h-32 rounded-full cursor-pointer object-cover shadow-1px-light">
      <i class="fa fa-circle-user cursor-pointer fa-6x color-grey "
         *ngIf="!user?.profile_image" ></i>
      <p class="primary-icon font-bold">{{user?.name}}</p>
    </div>
    <form [formGroup]="profileForm" class="space-y-6">
      <div class="flex flex-col md:flex-row gap-6">
        <div class="flex-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" placeholder="Enter Name"
                   [attr.readonly]="!isEditing ? true : null"/>
            <small class="text-red-500" *ngIf="submitted && profileForm.get('name').hasError('required')">
              Name field is required
            </small>
          </mat-form-field>
        </div>
        <div class="flex-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" placeholder="Enter Username"
                   [attr.readonly]="!isEditing ? true : null"/>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col md:flex-row gap-6">
        <div class="flex-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" placeholder="Enter Email"
                   [attr.readonly]="!isEditing ? true : null"/>
            <small class="text-red-500" *ngIf="submitted && profileForm.get('email').hasError('required')">
              Email field is required
            </small>
          </mat-form-field>
        </div>
        <div class="flex-1">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Contact</mat-label>
            <input matInput formControlName="contact" placeholder="Enter Contact"
                   [attr.readonly]="!isEditing ? true : null" type="text" required numbersOnly/>
            <small class="text-red-500" *ngIf="submitted && profileForm.get('contact').hasError('required')">
              Contact field is required
            </small>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap justify-center items-center mt-6 gap-4 ">
        <!--      <button mat-raised-button color="accent" type="button" (click)="updateProfile()"-->
        <!--              class="w-full sm:w-[48%] py-2 px-4 hover:shadow-xl transition duration-300 rounded-lg shadow-md"-->
        <!--              style="background:#6752A9 ; color: #fefefb;">-->
        <!--        <i class="fa-solid mr-2 fa-floppy-disk" *ngIf="!isEditing"></i>-->
        <!--        &lt;!&ndash;<i class="fa fa-pen-to-square mr-2" *ngIf="!isEditing"></i>&ndash;&gt;-->
        <!--        <i class="fa fa-save mr-2" *ngIf="isEditing"></i>-->
        <!--        {{ isEditing ? 'Save Profile' : 'Update Profile' }}-->
        <!--      </button>-->
        <button mat-raised-button type="button" (click)="openPasswordModal()"
                class="w-full sm:w-[48%]  py-2 px-4 hover:shadow-xl transition duration-300 rounded-lg shadow-md bg-dark-grey">
          <i class="fa fa-lock me-2"></i>
          Update Password
        </button>
      </div>
    </form>
  </div>
</div>
<ng-template #showPasswordModal>
  <mat-card-header class="m-b-20px subheading-2 bg-dark-grey p-2">
    <mat-card-title mat-dialog-title>
      <i class="fa-solid fa-trash text-danger"></i>
      <span class="subheading-2 text-xl ml-15px" [style.font-family]="'var(--vex-font)'">Change Password</span>
      <i class="fa fa-window-close text-red-700 text-xl float-right text-bold cursor-pointer"
         (click)="dialog.closeAll()"></i>
    </mat-card-title>
  </mat-card-header>
  <div mat-dialog-content>
    <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">
      <mat-form-field appearance="outline" class="w-full mb-4">
        <mat-label>Old Password</mat-label>
        <input matInput type="password" formControlName="currentPassword" placeholder="Enter Old Password"/>
        <small class="text-red-500" *ngIf="submitted && passwordForm.get('currentPassword').hasError('required')">
          Current password field is required
        </small>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full mb-4">
        <mat-label>New Password</mat-label>
        <input matInput type="password" formControlName="newPassword" placeholder="Enter New Password"/>
        <small class="text-red-500" *ngIf="submitted && passwordForm.get('newPassword').hasError('required')">
          New password field is required
        </small>
        <small class="text-red-500" *ngIf="submitted && passwordForm.get('newPassword')?.errors?.minlength">
          New Password must be at least {{ passwordForm.get('newPassword').errors?.minlength?.requiredLength }}
          characters long
        </small>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full mb-4">
        <mat-label>Confirm New Password</mat-label>
        <input matInput type="password" formControlName="confirmNewPassword" placeholder="Confirm New Password"/>
        <small class="text-red-500" *ngIf="submitted && passwordForm.get('confirmNewPassword').hasError('required')">
          Confirm new password field is required
        </small>
        <small class="text-red-500" *ngIf="submitted && passwordForm.get('confirmNewPassword')?.errors?.minlength">
          Confirm New Password must be at
          least {{ passwordForm.get('confirmNewPassword')?.errors?.minlength?.requiredLength }} characters long
        </small>
      </mat-form-field>
      <div class="flex justify-between mt-4">
        <button type="button" (click)="closeModal()" mat-button class="text-gray-500 hover:text-gray-700">
          Cancel
        </button>
        <button type="submit" mat-raised-button
                class="ml-2 py-2 px-4 shadow-lg hover:shadow-xl transition duration-300 rounded-lg"
                style="background:#6752A9 ; color: #fefefb;">
          <i class="fa fa-unlock mr-2"></i>
          Update
        </button>
      </div>
    </form>
  </div>
<!--  <div class="p-15px flex justify-between bg-dark-grey">-->
<!--    <button mat-raised-button class="cursor-pointer"-->
<!--            [style.font-family]="'var(&#45;&#45;vex-font)'"-->
<!--            (click)="closeModal()">-->
<!--      No-->
<!--    </button>-->
<!--    <button mat-raised-button color="warn" class="cursor-pointer"-->
<!--            [style.font-family]="'var(&#45;&#45;vex-font)'" (click)="delete()">-->
<!--      Yes-->
<!--    </button>-->
<!--  </div>-->
</ng-template>
<ng-template #imageUpdateDialog>
  <mat-card-header class="m-b-20px subheading-2 purple-color p-2">
    <mat-card-title mat-dialog-title>
          <span class="subheading-2 text-xl white-color" [style.font-family]="'var(--vex-font)'"><i
            class="fa fa-upload text-xl"></i>  Update Profile Picture</span>
      <i class="fa fa-window-close color-white text-xl float-right text-bold cursor-pointer"
         (click)="dialog.closeAll()"></i>
    </mat-card-title>
  </mat-card-header>
  <div mat-dialog-content>
    <div class="text-l" [style.font-family]="'var(--vex-font)'">
      <div class="flex flex-wrap justify-content-center items-center p-2">
        <div class="mx-auto">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          ></image-cropper>
        </div>
        <div
          id="file-upload-box"
          class="border-2 mx-auto border-purple p-4 w-full mt-3 text-center cursor-pointer"
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event)"
          (click)="fileInput.click()"
        >
          <input
            #fileInput
            type="file"
            class="file-input hidden"
            (change)="getImage($event)"
          />
          <div class="flex justify-content-center item-center">
            <img src="/assets/profile-logos/file-upload1.png" alt="Upload File"/>
          </div>
          <p class="text-gray-600 pt-2" [style.font-family]="'var(--vex-font)'">Click here to browse</p>
        </div>
      </div>
    </div>
  </div>
  <div class="p-15px flex justify-between purple-color">
    <button mat-raised-button class="cursor-pointer"
            [style.font-family]="'var(--vex-font)'"
            (click)="dialog.closeAll()">
      Cancel
    </button>
    <button *ngIf="imageChangedEvent" mat-raised-button class="cursor-pointer"
            [style.font-family]="'var(--vex-font)'"
            (click)="fileChangeEvent(croppedImage, 'PROFILE_IMAGE')">
      Save
    </button>
  </div>
</ng-template>
