import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router} from '@angular/router';
// @ts-ignore
import {fadeInUp400ms} from '@vex/animations/fade-in-up.animation';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
// @ts-ignore
import {VexPageLayoutComponent} from '@vex/components/vex-page-layout/vex-page-layout.component';
// @ts-ignore
import {VexSecondaryToolbarComponent} from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';
// @ts-ignore
import {VexBreadcrumbsComponent} from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ErrorStateMatcher, MatOptionModule, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {AsyncPipe, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {StorageService} from '../../shared/services/storage.service';
import {AuthService} from '../../shared/services/auth.service';
import {GlobalService} from '../../shared/services/global.service';
import {UserService} from '../../shared/services/user.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AlertService} from '../../shared/services/alert.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {HeaderComponent} from "../../landing/header/header.component";
import {FooterComponent} from "../../landing/footer/footer.component";
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'vex-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    VexPageLayoutComponent,
    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    MatIconModule,
    VexBreadcrumbsComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgIf,
    ReactiveFormsModule,
    NgFor,
    MatSlideToggleModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    AsyncPipe,
    HeaderComponent,
    FooterComponent
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ],
  providers: [
    GlobalService,
    AuthService,
    UserService,
    SessionService,
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  signInForm: FormGroup = new FormGroup({});
  //otpForm: FormGroup = new FormGroup({});
  inputType: any = 'password';
  visible: any = false;
  submitted: any = false;
  isBusy: any = false;
  organisations: any = [];
  sessions: any = [];
  formType: string = 'login';
  signInParams: any = {};
  userImageUrl: string;
  is_submitting: boolean = false;
  myControl: FormControl = new FormControl('');
  pageExtras: {} = {
    'buttonType': 'search',
    'imageUrl': '',
    'profileImage': ''
  }

  constructor(private router: Router,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private authService: AuthService,
              private userService: UserService,
              private globalService: GlobalService,
              private sessionService : SessionService,
              private alertService : AlertService
  ) {
    this.checkLogin();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.loginForm = this.fb.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required, Validators.minLength(6)]]
    });
    this.signInForm = this.fb.group({
      'name': ['', Validators.required],
      'email': ['', Validators.required],
      'contact': ['', Validators.required],
      'organisation_name': ['', Validators.required],
      'username': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(6)]]
    });
    /*this.otpForm = this.fb.group({
      'otp': ['', Validators.required]
    });*/

  }


  checkLogin(): void {
    if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
      this.globalService.setAccessToken(StorageService.getItem('accessToken'));
      this.globalService.setSelf(StorageService.getItem('self'));
      this.reloadSelf();
    } else {
      this.router.navigateByUrl('auth/login');
    }
  }

  reloadSelf(): void {
    this.authService.self({all: true}).subscribe((data) => {
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type,
        'organisation_id': data.organisation_id
      });
      this.router.navigateByUrl('/');
    }, e => {
      StorageService.clearAll();
      this.router.navigateByUrl('auth/login');
    });
  }

  getUsers(): void {
    this.organisations = [];
    const params: {} = {
      organisation_name: (this.loginForm && this.loginForm.getRawValue() && this.loginForm.getRawValue().organisation_name) ? this.loginForm.getRawValue().organisation_name : ''
    }
    if (params['organisation_name']) {
      this.userService.get(params).subscribe(data => {
        if (data && data['data'].length) {
          this.organisations = data['data'][0];
          this.pageExtras['imageUrl'] = (this.organisations && this.organisations['image']) ? this.organisations['image'] : '';
          this.pageExtras['profileImage'] = (this.organisations && this.organisations['profile_image']) ? this.organisations['profile_image'] : '';
          this.pageExtras['buttonType'] = 'login';
          this.cd.detectChanges();
        }
      });
    }
  }

  login(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.submitted) {
      if (this.checkLoggedInStatus()) {
        this.setSelfData();
        this.router.navigateByUrl('/home');
      } else {
        let params: {} = {
          'username': (this.loginForm.getRawValue() && this.loginForm.getRawValue().username) ? this.loginForm.getRawValue().username : '',
          'password': (this.loginForm.getRawValue() && this.loginForm.getRawValue().password) ? this.loginForm.getRawValue().password : '',
          // 'session_id': (this.loginForm.getRawValue() && this.loginForm.getRawValue().session_id) ? this.loginForm.getRawValue().session_id : '1',
        };
        this.authService.login(params).subscribe(data => {
          if (data && data.id) {
            this.globalService.setAccessToken(data?.token);
            this.globalService.setSelf({
              'name': data?.name,
              'email': data?.email,
              'contact': data?.contact,
              'user_id': data?.id,
              'username': data?.username,
              'user_type': data?.user_type,
              /*'organisation_id': data?.organisation_id,
              'organisation_name': data?.organisation_name,*/
              'image': data?.image
            });
            const checkout = localStorage.getItem('checkout');
            if (checkout) {
              this.router.navigateByUrl(checkout);
              this.loginForm.reset();
              localStorage.removeItem('checkout');
            } else {
              this.router.navigateByUrl('/home');
              this.loginForm.reset();
            }
          }
        }, error => {
          this.isBusy = false;
          this.submitted = false;
          if (error?.error?.type == 'RESOURCE_CREATION_FAILED'){
            this.alertService.showErrors(error.error.errorDetails)
          }
        });
      }
    }
  }

  checkLoggedInStatus() {
    const accessToken = StorageService.getItem('accessToken');
    return accessToken && accessToken !== 'null';
  }

  setSelfData(): void {
    this.authService.self({all: true}).subscribe((data) => {
      this.isBusy = false;
      this.globalService.setSelf({
        'name': data.name,
        'email': data.email,
        'contact': data.contact,
        'user_id': data.id,
        'username': data.username,
        'user_type': data.user_type
      });
      return;
    }, e => {
      this.isBusy = false;
      StorageService.clearAll();
    });
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  registerFormVisible(): void {
    this.formType = 'signIn';
  }

  loginFormVisible(): void {
    this.formType = 'login';
  }

  /*otp(): void {
    this.submitted = true;
    if (!this.signInForm.valid) {
      return;
    }
    if (this.submitted) {
      const params: {} = {
        'organisation_name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().organisation_name) ? this.signInForm.getRawValue().organisation_name : '',
        'name': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().name) ? this.signInForm.getRawValue().name : '',
        'email': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().email) ? this.signInForm.getRawValue().email : '',
        'contact': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().contact) ? this.signInForm.getRawValue().contact : '',
        'username': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().username) ? this.signInForm.getRawValue().username : '',
        'password': (this.signInForm && this.signInForm.getRawValue() && this.signInForm.getRawValue().password) ? this.signInForm.getRawValue().username : ''
      };
      this.authService.otp(params).subscribe((data) => {
        if (data && data.decoded_otp) {
          this.signInParams = params;
          this.signInParams.decoded_otp = data.decoded_otp;
        }
      });
      this.formType = 'otpForm';
    }
  }*/

  /*signIn(): void {
    this.is_submitting = true;
    this.signInParams.otp = (this.otpForm && this.otpForm.getRawValue() && this.otpForm.getRawValue().otp) ? this.otpForm.getRawValue().otp : null;
    this.authService.register(this.signInParams).subscribe(data => {
        if (data && data.id) {
          this.globalService.setAccessToken(data.token);
          this.globalService.setSelf({
            'name': data.name,
            'email': data.email,
            'contact': data.contact,
            'user_id': data.id,
            'username': data.username,
            'user_type': data.user_type,
            'organisation_id': data.organisation_id
          });
          this.otpForm.reset();
          this.router.navigateByUrl('/');
        }
      }
    );
    setTimeout(() => {
      this.is_submitting = false;
    }, 3000);
  }*/

  /*private _filter(value: string): any {
    const filterValue: any = value.toLowerCase();
    return this.organisations.filter(organisation => organisation.organisation_name.toLowerCase().includes(filterValue));
  }*/

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
