
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { content, landing } from './shared/routes/routes';
import { LoginComponent } from './auth/login/login.component';
import { LandingComponent } from './landing/pages-list/Landing/Landing.component';
import { ProfileComponent } from './layouts/components/profile/profile.component';

export const appRoutes: VexRoutes = [

  {
    path: '',
    component: LayoutComponent,
    children: content
  },
  {
    path: 'landing',
    component: LandingComponent,
    children: landing
  },
  {
    path: 'auth/login',
    component: LoginComponent
  },
  {
    path : 'profile',
    component: ProfileComponent,
  }
];
