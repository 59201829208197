import {Injectable} from '@angular/core';
import {VexLayoutService} from '@vex/services/vex-layout.service';
import {NavigationItem} from './navigation-item.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'Dashboard',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Home',
            route: '/home',
            icon: 'mat:home',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'General',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Application',
            route: '/general/application',
            icon: 'mat:chrome_reader_mode',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Attendance',
            route: '/general/attendance',
            icon: 'mat:check_circle',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Accounts',
            route: '/general/accounts',
            icon: 'mat:account_balance',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'MODULES',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Courses',
            route: '/modules/courses',
            icon: 'mat:chrome_reader_mode',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Batches',
            route: '/modules/batches',
            icon: 'mat:chrome_reader_mode',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Subjects',
            route: '/modules/subjects',
            icon: 'mat:chrome_reader_mode',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Class',
            route: '/modules/classes',
            icon: 'mat:check_circle',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Assignments',
            route: '/modules/classes/assignments',
            icon: 'mat:assignment',
            routerLinkActiveOptions: {exact: true},
            availableTo: ['STUDENT']
          }
        ]
      },
      {
        type: 'subheading',
        label: 'DOCS',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Id Card',
            route: '/docs/id-card',
            icon: 'mat:credit_card',
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Marksheet',
            route: '/docs/marksheet',
            icon: 'mat:assignment',
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Certificate',
            route: '/docs/certificate',
            icon: 'mat:workspace_premium',
            availableTo: ['STUDENT']
          }
        ]
      },

      // {
      //   type: 'subheading',
      //   label: 'LMS',
      //   availableTo: ['STUDENT'],
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'SLM',
      //       route: '/lms/slm',
      //       icon: 'mat:desktop_windows',
      //       availableTo: ['STUDENT']
      //     },
      //     {
      //       type: 'link',
      //       label: 'Notes',
      //       route: '/lms/notes',
      //       icon: 'mat:notes',
      //       availableTo: ['STUDENT']
      //     },
      //     {
      //       type: 'link',
      //       label: 'Assignments',
      //       route: '/lms/assignments',
      //       icon: 'mat:assessment',
      //       availableTo: ['STUDENT']
      //     },
      //     {
      //       type: 'link',
      //       label: 'Subjects',
      //       route: '/lms/subjects',
      //       icon: 'mat:book',
      //       availableTo: ['STUDENT']
      //     }
      //   ]
      // },
      {
        type: 'subheading',
        label: 'EXAM',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Scheduled Exam',
            route: '/exam/scheduled-exam',
            icon: 'mat:access_time',
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Result',
            route: '/exam/result',
            icon: 'mat:bar_chart',
            availableTo: ['STUDENT']
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Utilities',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'dropdown',
            label: 'Library',
            icon: 'mat:library_books',
            availableTo: ['STUDENT'],
            children: [
              {
                type: 'link',
                label: 'Issued Books',
                route: '/utilities/library/issued-books',
                availableTo: ['STUDENT']
              },
              {
                type: 'link',
                label: 'Library Transaction',
                route: '/utilities/library/transaction',
                availableTo: ['STUDENT']
              }
            ]
          },
          {
            type: 'link',
            label: 'Hostel',
            route: '/utilities/hostel',
            icon: 'mat:business',
            availableTo: ['STUDENT']
          },
        ]
      },
     /* {
        type: 'subheading',
        label: 'LMS',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'SLMs',
            route: '/lms/slms',
            icon: 'mat:laptop_chromebook',
            availableTo: ['STUDENT']
          },
          {
            type: 'link',
            label: 'Assignments',
            route: '/lms/assignments',
            icon: 'mat:assignment',
            availableTo: ['STUDENT']
          }
        ]
      },*/
      {
        type: 'subheading',
        label: 'Support',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'dropdown',
            label: 'Support',
            icon: 'mat:contact_support',
            availableTo: ['STUDENT'],
            children: [
              {
                type: 'link',
                label: 'Admission Tickets',
                route:'/support/admission-tickets',
                availableTo: ['STUDENT']
              },
              {
                type: 'link',
                label: 'Examination Tickets',
                route:'/support/examination-tickets',
                availableTo: ['STUDENT']
              },
              // {
              //   type: 'link',
              //   label: 'Marksheet Tickets',
              //   route:'/support/marksheet-tickets',
              //   availableTo: ['STUDENT']
              // },
              {
                type: 'link',
                label: 'Certificate Tickets',
                route:'/support/certificate-tickets',
                availableTo: ['STUDENT']
              },
              {
                type: 'link',
                label: 'Other Tickets',
                route:'/support/other-tickets',
                availableTo: ['STUDENT']
              },
              /*{
                type: 'link',
                label: 'Add',
                route: '/support/add-tickets',
                availableTo: ['STUDENT']
              },
              {
                type: 'link',
                label: 'List',
                route: '/support/list',
                availableTo: ['STUDENT']
              }*/
            ]
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Notification',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'link',
            label: 'Notifications',
            route: '/notification',
            icon: 'mat:notification_important',
            availableTo: ['STUDENT']
          }
        ]
      },
     /* {
        type: 'subheading',
        label: 'General',
        availableTo: ['STUDENT'],
        children: [
          {
            type: 'dropdown',
            label: 'Admins',
            icon: 'mat:admin_panel_settings',
            availableTo: ['STUDENT'],
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/admin/add',
                availableTo: ['STUDENT']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/admin/list',
                availableTo: ['STUDENT']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Sessions',
            icon: 'mat:access_time',
            availableTo: ['STUDENT', 'ADMIN'],
            children: [
              {
                type: 'link',
                label: 'Add',
                route: '/general/session/add',
                availableTo: ['STUDENT', 'ADMIN']
              },
              {
                type: 'link',
                label: 'List',
                route: '/general/session/list',
                availableTo: ['STUDENT', 'ADMIN']
              }
            ]
          }
        ]
      },*/
    ]);
  }
}
